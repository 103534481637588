import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Log from '../Log'
import iconImage from '../static/images/logo.png'
import textImage from '../static/images/text.png'

const useStyles = makeStyles(() => ({
  text: {
    textAlign: 'center',
    '& > a': {
      textDecoration: 'none',
    },
    '& > a > p': {
      fontSize: '24px',
      color: '#000',
      fontWeight: '800',
    },
  },
}))

function Logo({ link }) {
  const classes = useStyles()

  const onClick = () => {
    Log('click')
  }

  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
    >
      <Grid item>
        <a href={link} onClick={onClick}>
          <img widht="90" height="90" src={iconImage} alt="STRETCHIT" />
        </a>
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <a href={link} onClick={onClick}>
          <img widht="127" height="16" src={textImage} alt="STRETCHIT" />
        </a>
      </Grid>
    </Grid>
  )
}

Logo.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Logo
