/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes, { func } from 'prop-types'
import {
  Container, Grid, Typography, Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './Button'
import Logo from './Logo'

import girlImage from '../static/images/girl.jpg'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  text: {
    fontFamily: 'Oswald',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '36px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(4),
  },
}))

function Head({ link }) {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.container}
    >
      <Grid item>
        <Logo link={link} />
      </Grid>

      <Grid item xs={12}>
        <img className={classes.image} src={girlImage} alt="STRETCHIT" />
      </Grid>

      <Grid item>
        <Typography className={classes.text}>Your personal stretching coach</Typography>
      </Grid>

      <Grid item xs={12} className={classes.button}>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={10}
            sm={8}
            md={6}
          >
            <Button href={link}>Try For free</Button>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

Head.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Head
