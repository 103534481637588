import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

// A custom theme for this app
export const MainTheme = responsiveFontSizes(
  createTheme({
    // typography: {
    //   fontFamily: '\'Rubik\', sans-serif',
    // },
    palette: {
      background: {
        default: '#FFF',
      },
    },
  }),
)

export default MainTheme
