/* eslint-disable no-unused-vars */
import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    // marginTop: theme.spacing(1) * -1,
    paddingBottom: theme.spacing(1) * -1,
    zIndex: '1',
  },
  ellipse: {
    width: '220%',
    marginLeft: '-60%',
    borderRadius: '50%',
    background: '#F2F2F2',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(15),
    height: theme.spacing(40),
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(40),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(45),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(60),
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(65),
    },
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(90),
    },
  },
}))

function Ellipse() {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.ellipse} />
      </Grid>
    </Grid>
  )
}

export default Ellipse
