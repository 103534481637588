/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Button from './Button'
import Head from './Head'
import Ellipse from './Ellipse'
import PhoneCarousel from './PhoneCarousel'
import Carousel from './Carousel'

import item1Image from '../static/images/item1.svg'
import item2Image from '../static/images/item2.svg'
import item3Image from '../static/images/item3.svg'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    paddingBottom: theme.spacing(7),
  },
  header: {
    textAlign: 'center',
    fontFamily: 'Oswald',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '30px',
    textTransform: 'uppercase',
  },
  itemHeader: {
    textAlign: 'center',
    fontFamily: 'Oswald',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px',
    textTransform: 'uppercase',
  },
  itemText: {
    textAlign: 'center',
    fontFamily: 'Rubik',
    fontSize: '16px',
  },
  phoneCarousel: {
    marginTop: theme.spacing(-35),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(-35),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-40),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-55),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(-60),
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(-85),
    },
  },
  carousel: {
    marginTop: theme.spacing(12),
  },
  items: {
    marginTop: theme.spacing(5),
  },
  item: {
    marginTop: theme.spacing(5),
  },
}))

function LangdingPage({ link }) {
  const classes = useStyles()

  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
      >
        <Grid
          container
          justifyContent="center"
          className={classes.container}
        >
          <Grid item>
            <Head link={link} />
          </Grid>

          <Grid item xs={12}>
            <Ellipse />
          </Grid>

          <Grid item xs={12} className={classes.phoneCarousel}>
            <PhoneCarousel />
          </Grid>

          <Grid item xs={12} className={classes.carousel}>
            <Grid container justifyContent="center">
              <Grid item xs={12} className={classes.headerContainer}>
                <Typography className={classes.header}>HOW STRETCHIT WORKS</Typography>
              </Grid>
              <Grid item xs={10} md={8} lg={6}>
                <Carousel />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={10} className={classes.items}>

            <Grid container justifyContent="center" className={classes.item}>
              <Grid item>
                <img src={item1Image} alt="" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.itemHeader}>Choose your flexibility goal</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.itemText}>Unique stretching classes focused on Full Body, Front Splits, Bendy Back, Middle Splits and Lose Weight.</Typography>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" className={classes.item}>
              <Grid item>
                <img src={item2Image} alt="" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.itemHeader}>Create a training program</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.itemText}>30-day training programs designed for you by a professional trainer</Typography>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" className={classes.item}>
              <Grid item>
                <img src={item3Image} alt="" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.itemHeader}>Track your results</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.itemText}>Workout tracker logs your training history and calories burned.</Typography>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12}>
            <Footer link={link} />
          </Grid>

        </Grid>
      </Container>
    </>
  )
}

LangdingPage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default LangdingPage
