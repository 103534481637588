/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Grid } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import { makeStyles } from '@material-ui/core/styles'

import Slide1 from '../static/images/11slide3x.jpg'
import Slide2 from '../static/images/22slide3x.jpg'
import Slide3 from '../static/images/33slide3x.jpg'
import Slide4 from '../static/images/44slide3x.jpg'
import Slide5 from '../static/images/55slide3x.jpg'

const useStyles = makeStyles((theme) => ({
  controls: {
    marginTop: theme.spacing(1),
  },
  controlItem: {
    transition: 'background-color 0.7s ease',
    margin: theme.spacing(1),
    width: '12px',
    height: '12px',
    backgroundColor: '#9B9B9B',
  },
  controlItemSelected: {
    backgroundColor: '#000',
    margin: theme.spacing(1),
    width: '12px',
    height: '12px',
  },
}))

function CustomCarousel() {
  const classes = useStyles()

  const [selectedItem, setSelectedItem] = useState(0)

  const slides = [
    Slide1,
    Slide2,
    Slide3,
    Slide4,
    Slide5,
  ]

  const onChange = (i) => {
    setSelectedItem(i)
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <Carousel
          showThumbs={false}
          autoPlay
          infiniteLoop
          showStatus={false}
          interval={1500}
          showIndicators={false}
          selectedItem={selectedItem}
          onChange={onChange}
        >
          {slides.map((slide) => (
            <div key={slide}>
              <img className={classes.slide} src={slide} alt="" />
            </div>
          ))}
        </Carousel>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="center"
          className={classes.controls}
        >
          {slides.map((slide, i) => {
            let className = classes.controlItem

            if (i === selectedItem) {
              className = classes.controlItemSelected
            }

            return (
              <Grid
                key={slide}
                item
                className={className}
                onClick={() => {
                  onChange(i)
                }}
              />
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomCarousel
